
<template>
  <div>
    <el-form label-width="95px" inline size="mini">
      <el-form-item label="发放类型">
        <el-select style="width:120px;" v-model="search.batchType" placeholder="选择发放类型" @change="handleCurrentChange(1)">
          <el-option v-for="(item,i) in batchTypeList" :key="i" :label="item.label" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模糊查询：" label-width="120px">
        <el-input style="width:130px;" placeholder="请输入客户名称/手机号" v-model="search.search"></el-input>
      </el-form-item>
      <OrganizationSelect v-model="search.submitOrg" @reload="getList"></OrganizationSelect>
      <el-form-item label="发放时间：">
        <el-date-picker style="width:314px;" v-model="createTime" type="daterange" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleCurrentChange(1)">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" style="box-shadow: 0 1px 4px 0 rgba(0,0,0,0.1);overflow:auto">
      <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="organizationName" align="center" label="所属机构"></el-table-column>
      <el-table-column align="center" label="发放类型">
        <template slot-scope="scope">
          {{scope.row.exchangeType==0?'核销码':scope.row.exchangeType==1?'前三后四':scope.row.exchangeType==2?'直接充值':''}}
        </template>
      </el-table-column>
      <el-table-column prop="exchangeAmount" align="center" label="面额（元）" width="100"></el-table-column>
      <el-table-column prop="usedName" align="center" label="客户"></el-table-column>
      <el-table-column prop="usedPhone" align="center" label="手机"></el-table-column>
      <el-table-column prop="createBy" align="center" label="创建人"></el-table-column>
      <el-table-column prop="createTime" align="center" label="创建时间" width="180"></el-table-column>
      <el-table-column prop="auditStatus" align="center" label="审批状态" width="120">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" disable-transitions :type="auditStatusTag[scope.row.auditStatus].type" v-text="auditStatusTag[scope.row.auditStatus].text">
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="isUse" align="center" label="状态" width="120">
        <template slot-scope="scope">
          <el-tag class="el-tag-style" type="primary" disable-transitions v-if="scope.row.isReceive == 0">未领取</el-tag>
          <el-tag class="el-tag-style" type="success" disable-transitions v-if="scope.row.isReceive == 1">已领取</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="receiveTime" align="center" label="领取时间" width="180"></el-table-column>
      <el-table-column prop="isUse" align="center" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-view" @click="gotoInfo(scope.row)" style="color:#333">查看</el-button>
          <el-button icon="el-icon-refresh-left" size="mini" type="text" @click="recall(scope.row.id)" v-show="scope.row.isReceive == 0">撤回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination layout="total,prev, pager, next,sizes" @size-change="handleSizeChange" @current-change="handleCurrentChange" background :page-size="search.pageSize" :current-page="search.pageNum" :total="total">
    </el-pagination>
    <info :show.sync="dialogInfo" :info="info" v-if="dialogInfo"></info>
  </div>
</template>
<script>
import info from './components/info.vue'


import { queryAutonymList, recallAutonym } from '@/api/codes'
import { organizationList } from '@/api/setting';
import { getCookies, auditStatusTag } from '@/utils/utils'

import OrganizationSelect from '@/components/OrganizationSelect.vue'

export default {
  name: 'grant',
  components: {
    OrganizationSelect, info
  },
  data() {
    return {
      auditStatusTag: auditStatusTag,
      search: {
        submitOrg: '',
        batchType: '',
        isUse: '',
        amount: '',
        code: '',
        userName: '',
        search: '',
        pageNum: 1,//分页页数
        pageSize: 10,//每页显示多少
      },
      createTime: ['', ''],
      usedTime: ['', ''],
      batchTypeList: [{ label: '全部', id: '' }, { label: '现油充值', id: '2' }, { label: '现油核销码', id: '0' }, { label: '现油前三后四', id: '1' }],
      list: [],
      total: 0,//分页总页数
      dialogInfo: false,
      info: {}
    }
  },
  watch: {
    isAddDialog() {
      this.getList();
    },

  },
  created() {
    // this.search.submitOrg = getCookies('organizationId') == 1 ? '' : getCookies('organizationId')
    // this.getList();
  },
  methods: {
    recall(id) {
      let that = this;
      this.$confirm('是否撤回?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((res) => {
        recallAutonym({ 'id': id, 'batchType': that.batchType })
          .then((response) => {
            if (response.code == 200) {
              this.getList()
            }
            that.$message({
              type: response.code == 200 ? 'success' : 'default',
              duration: 2000,
              message: response.msg || response.error_msg,
            });
          })
      });
    },
    changeCheck(checkKey) {
      this.$nextTick(() => {
        this[checkKey] = this[checkKey].replace(/[^\w\.\/]/ig, '')
      })
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.search.pageNum = val;
      this.getList();
    },
    getList() {
      let param = Object.assign({}, this.search)
      param['userStartTime'] = this.usedTime[0];
      param['userEndTime'] = this.usedTime[1];
      param['startTime'] = this.createTime[0];
      param['endTime'] = this.createTime[1];
      queryAutonymList(param).then((response) => {
        if (response.code == 200) {
          this.total = response.data.total;
          this.list = response.data.records;
        }
      })
    },
    clearSearch() {
      Object.assign(this.$data.search, this.$options.data().search);
      this.createTime = ['', '']
      this.usedTime = ['', '']
      this.getList()
    },
    gotoInfo(item) {
      this.info = item
      this.dialogInfo = true
    },
  },
}
</script>